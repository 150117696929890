@font-face {
    font-family: 'Example';
    src: url('fonts/RBCGrotesk-Light.eot');
    src: local('☺'), url('fonts/RBCGrotesk-Light.woff') format('woff'),
    url('fonts/RBCGrotesk-Light.ttf') format('truetype'),
    url('fonts/RBCGrotesk-Light.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Nanum Myeongjo',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}